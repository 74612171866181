<template>
  <s-label
    v-bind="labelStyle"
    class="evolu-tag"
  >
    {{ EVOLU_SHEIN_TEXT }}
  </s-label>
</template>

<script>
import Vue from 'vue'
import { Label } from '@shein/sui-mobile'

Vue.use(Label)

export default {
  name: 'EvoluTag',
  props: {
    isNewCartrowVision: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      EVOLU_SHEIN_TEXT: 'evoluSHEIN',
    }
  },
  computed: {
    labelStyle () {
      if (this.isNewCartrowVision) {
        return {
          'color': '#A86104',
          'backgroundColor': '#FFEED9'
        }
      } else {
        return {
          'color': '#A86104',
          'backgroundColor': '#FFF5E9'
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
.evolu-tag {
  margin-right: 12/75rem;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  font-size: 20/75rem!important; /* stylelint-disable-line declaration-no-important */
}
</style>
