<template>
  <div ref="behaviorTag">
    <ClientOnly v-if="labelList.length">
      <div 
        ref="behaviorSwiper" 
        class="behavior-swiper"
      >
        <div
          class="behavior-list"
          :class="{ 'change-element': !stopBehaviorAnimation }"
          :style="{'animation': animationStyle, 'animation-play-state': stopBehaviorAnimation ? 'paused' : 'running'}"
        >
          <div 
            v-for="(item, index) in labelList"
            :key="index"
            v-expose="index == labelList.length - 1 ? {} : getAnalysis(item)"
            class="cart-item-behavior"
          >
            <Icon
              v-if="isNewCartrowVision"
              is-responsive-name
              color="#B46F14"
              size="13px"
              :name="item.icon"
            />
            <img
              v-else
              :src="item.iconSrc"
              width="13"
              height="13"
            />
            <span class="label-text">
              {{ getItemText(item) }}
            </span>
          </div>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
import { isEmpty, isObject, isArray } from '@shein/common-function'
import BehaviorLabel from 'public/src/pages/cartNew/utils/behaviorLabel.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Icon } from '@shein-aidc/icon-vue2'
// import { register } from 'swiper/element'
// import { Autoplay } from 'swiper/modules'
// if (typeof window !== 'undefined') {
//   register()
// }
daEventCenter.addSubscriber({ modulecode: '1-8-1' })
const SA_PREFIX = 'behavior_lable_prefix'

export default {
  name: 'CartItemBehaviorNew',
  components: {
    ClientOnly,
    Icon,
  },
  props: {
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    label: {
      type: [Array, Object],
      default: () => ({})
    },
    product: {
      type: Object,
      default() {
        return {}
      }
    },
    needSwipe: {
      type: Boolean,
      default: false,
    },
    batchActive: {
      type: Boolean,
      default: false,
    },
    isFirstPage: {
      type: Boolean,
      default: false,
    },
    isNewCartrowVision: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      exposedLabelIds: [],
      showSlide: false,
      stopBehaviorAnimation: false,
      // isInit: false,
    }
  },
  computed: {
    // 所有的标签
    baseLabel() {
      if (isEmpty(this.label)) return []
      // 兼容历史逻辑
      const arr = isObject(this.label) ? 
        [this.label] :
        (isArray(this.label) ? this.label : [])
      return arr.filter((v) => v.labelId)
    },
    // 排除不展示的标签
    labelList() {
      if (isEmpty(this.label)) return []
      let labelList2 = this.baseLabel.filter((v) => v.showLabelTag)
      return labelList2.length >= 2 ? [...labelList2, labelList2[0]] : labelList2
    },
    animationStyle() {
      return this.needSwipe && this.labelList.length > 1 ? `slide${this.labelList.length - 1} ${(this.labelList.length - 1) * 1.5}s infinite 0s ease-in-out normal forwards` : ''
    }
  },
  // watch: {
  //   labelList: {
  //     handler(n) {
  //       if (!n?.length) return
  //       if (typeof window != 'undefined') {
  //         if(this.isFirstPage && this.labelList.length > 1 && this.needSwipe){
  //           // this.initIntersectionObserverInstance()
  //         }
  //       }
  //     },
  //     immediate: true,
  //   }
  // },
  beforeDestroy() {
    this.dealWithLeave()
  },
  deactivated() {
    // 处理keep-alive离开时的埋点
    this.dealWithLeave()
  },
  mounted(){
    // this.initIntersectionObserverInstance()
  },
  activated(){
    // this.initIntersectionObserverInstance()
  },
  methods: {
    initIntersectionObserverInstance() {
      if (!this.needSwipe) return
      if(this.isInit) return
      this.isInit = true
      if(this.isFirstPage){
        this.changeAutoPlayStatus(true)
      }
      this.IntersectionObserverInstance = new IntersectionObserver(
        entries => {
          if (!this.$refs.behaviorSwiper) return
          if (this.batchActive) return
          const intersectionRatio = entries[0].intersectionRatio

          if (intersectionRatio > 0) {
            this.changeAutoPlayStatus(true)
          } else {
            this.changeAutoPlayStatus(false)
          }
        },
        {
          thresholds: [0, 1]
        }
      )

      this.$nextTick(() => {
        this.IntersectionObserverInstance.observe(this.$refs.behaviorTag)
      })
    },
    // initSwiper(batchActive) {
    //   requestIdleCallback(() => {
    //     if(!this.needSwiperInit) return
    //     if (!this.$refs.behaviorSwiper) return
    //     const options = {
    //       modules: this.needSwipe ? [Autoplay] : [],
    //       autoplay: { delay: 1500 },
    //       loop: true,
    //     }

    //     Object.assign(this.$refs.behaviorSwiper, options)
    //     this.showSlide = true
    //     this.$refs.behaviorSwiper.initialize()
    //     this.swiper = this.$refs.behaviorSwiper?.swiper
    //     if (batchActive && this.needSwipe) {
    //       this.changeAutoPlayStatus(false)
    //     }
    //   })
    // },
    changeAutoPlayStatus(play) {
      this.stopBehaviorAnimation = play ? false : true
      // if (play) {
      //   this.swiper?.autoplay?.start?.()
      // } else {
      //   this.swiper?.autoplay?.stop?.()
      //   const indexZero = this.swiper?.slides?.findIndex((v) => v.getAttribute('data-swiper-slide-index') == 0)
      //   this.swiper?.slideTo?.(indexZero, 0)
      // }
    },
    resetSaInfo() {
      // 重置v-expose
      const daEventExpose = daEventCenter.getExposeInstance()
      daEventExpose?.resetAll?.(SA_PREFIX)

      this.exposedLabelIds = []
    },
    getItemText(item) {
      return BehaviorLabel.getText(item, this.language)
    },
    getAnalysis(item) {
      return {
        id: '1-8-1-00000000', // 假id
        prefix: SA_PREFIX, // 用于重置埋点
        callback: () => {
          if (!this.needSwipe) return
          // 30，60，90天最低价特殊处理,60,90天实验不开则展示30天的标签id
          let labelId = item.labelGear ? item.showLabel.flag ? item.labelGear[item.showLabel.flag] : item.labelGear['30'] : item.labelId
          if (!this.exposedLabelIds.includes(labelId)) {
            this.exposedLabelIds.push(labelId)
          }
        },
      }
    },
    dealWithLeave() {
      // 购物车页面在 ABT 关闭时也需要上报埋点
      // this.isInit = false
      // this.IntersectionObserverInstance?.unobserve(this.$refs.behaviorTag)
      const arr = this.labelList.length == 0 ? this.baseLabel : this.labelList
      if (!this.needSwipe || !arr.length) return
      daEventCenter.triggerNotice({
        daId: '1-8-1-40',
        extraData: {
          userbehavior_tips: arr.map((v) => v.labelGear ? v.showLabel.flag ? v.labelGear[v.showLabel.flag] : v.labelGear['30'] : v.labelId).join(','),
          userbehavior_tips_show: this.exposedLabelIds.join(','),
          goods_id: this.product?.goods_id,
        }
      })

      this.resetSaInfo()
    }
  },
}
</script>

<style lang="less">
.behavior-swiper {
  margin: 0;
  height: 18px;
  overflow: hidden;
  display: inline-block;
  contain: layout;
}
.cart-item-behavior {
  overflow: hidden;
  font-size: 12px;
  color: @sui_color_micro_emphasis;
  display: inline-flex;
  align-items: center;
  width: 100%;
  .label-text {
    margin-left: 4/75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* rtl:begin:ignore */
    direction: ltr;
  }
}
.behavior-list {
  scroll-snap-type: y mandatory;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  &.change-element{
    will-change: transform;
  }
}
// buildKeyFramesAndReturnCSSInfo('slide',false,2,.2,1.3)
@keyframes slide2 { 0%{transform:translateY(0%);}43.33%{transform:translateY(-0.00%);}50.00%{transform:translateY(-33.33%);}93.33%{transform:translateY(-33.33%);}100.00%{transform:translateY(-66.67%);}}
@keyframes slide3 { 0%{transform:translateY(0%);}28.89%{transform:translateY(-0.00%);}33.33%{transform:translateY(-25.00%);}62.22%{transform:translateY(-25.00%);}66.67%{transform:translateY(-50.00%);}95.56%{transform:translateY(-50.00%);}100.00%{transform:translateY(-75.00%);}}
@keyframes slide4 { 0%{transform:translateY(0%);}21.67%{transform:translateY(-0.00%);}25.00%{transform:translateY(-20.00%);}46.67%{transform:translateY(-20.00%);}50.00%{transform:translateY(-40.00%);}71.67%{transform:translateY(-40.00%);}75.00%{transform:translateY(-60.00%);}96.67%{transform:translateY(-60.00%);}100.00%{transform:translateY(-80.00%);}}
@keyframes slide5 { 0%{transform:translateY(0%);}17.33%{transform:translateY(-0.00%);}20.00%{transform:translateY(-16.67%);}37.33%{transform:translateY(-16.67%);}40.00%{transform:translateY(-33.33%);}57.33%{transform:translateY(-33.33%);}60.00%{transform:translateY(-50.00%);}77.33%{transform:translateY(-50.00%);}80.00%{transform:translateY(-66.67%);}97.33%{transform:translateY(-66.67%);}100.00%{transform:translateY(-83.33%);}}
@keyframes slide6 { 0%{transform:translateY(0%);}14.44%{transform:translateY(-0.00%);}16.67%{transform:translateY(-14.29%);}31.11%{transform:translateY(-14.29%);}33.33%{transform:translateY(-28.57%);}47.78%{transform:translateY(-28.57%);}50.00%{transform:translateY(-42.86%);}64.44%{transform:translateY(-42.86%);}66.67%{transform:translateY(-57.14%);}81.11%{transform:translateY(-57.14%);}83.33%{transform:translateY(-71.43%);}97.78%{transform:translateY(-71.43%);}100.00%{transform:translateY(-85.71%);}}
@keyframes slide7 { 0%{transform:translateY(0%);}12.38%{transform:translateY(-0.00%);}14.29%{transform:translateY(-12.50%);}26.67%{transform:translateY(-12.50%);}28.57%{transform:translateY(-25.00%);}40.95%{transform:translateY(-25.00%);}42.86%{transform:translateY(-37.50%);}55.24%{transform:translateY(-37.50%);}57.14%{transform:translateY(-50.00%);}69.52%{transform:translateY(-50.00%);}71.43%{transform:translateY(-62.50%);}83.81%{transform:translateY(-62.50%);}85.71%{transform:translateY(-75.00%);}98.10%{transform:translateY(-75.00%);}100.00%{transform:translateY(-87.50%);}}
@keyframes slide8 { 0%{transform:translateY(0%);}10.83%{transform:translateY(-0.00%);}12.50%{transform:translateY(-11.11%);}23.33%{transform:translateY(-11.11%);}25.00%{transform:translateY(-22.22%);}35.83%{transform:translateY(-22.22%);}37.50%{transform:translateY(-33.33%);}48.33%{transform:translateY(-33.33%);}50.00%{transform:translateY(-44.44%);}60.83%{transform:translateY(-44.44%);}62.50%{transform:translateY(-55.56%);}73.33%{transform:translateY(-55.56%);}75.00%{transform:translateY(-66.67%);}85.83%{transform:translateY(-66.67%);}87.50%{transform:translateY(-77.78%);}98.33%{transform:translateY(-77.78%);}100.00%{transform:translateY(-88.89%);}}
@keyframes slide9 { 0%{transform:translateY(0%);}9.63%{transform:translateY(-0.00%);}11.11%{transform:translateY(-10.00%);}20.74%{transform:translateY(-10.00%);}22.22%{transform:translateY(-20.00%);}31.85%{transform:translateY(-20.00%);}33.33%{transform:translateY(-30.00%);}42.96%{transform:translateY(-30.00%);}44.44%{transform:translateY(-40.00%);}54.07%{transform:translateY(-40.00%);}55.56%{transform:translateY(-50.00%);}65.19%{transform:translateY(-50.00%);}66.67%{transform:translateY(-60.00%);}76.30%{transform:translateY(-60.00%);}77.78%{transform:translateY(-70.00%);}87.41%{transform:translateY(-70.00%);}88.89%{transform:translateY(-80.00%);}98.52%{transform:translateY(-80.00%);}100.00%{transform:translateY(-90.00%);}}
@keyframes slide10 { 0%{transform:translateY(0%);}8.67%{transform:translateY(-0.00%);}10.00%{transform:translateY(-9.09%);}18.67%{transform:translateY(-9.09%);}20.00%{transform:translateY(-18.18%);}28.67%{transform:translateY(-18.18%);}30.00%{transform:translateY(-27.27%);}38.67%{transform:translateY(-27.27%);}40.00%{transform:translateY(-36.36%);}48.67%{transform:translateY(-36.36%);}50.00%{transform:translateY(-45.45%);}58.67%{transform:translateY(-45.45%);}60.00%{transform:translateY(-54.55%);}68.67%{transform:translateY(-54.55%);}70.00%{transform:translateY(-63.64%);}78.67%{transform:translateY(-63.64%);}80.00%{transform:translateY(-72.73%);}88.67%{transform:translateY(-72.73%);}90.00%{transform:translateY(-81.82%);}98.67%{transform:translateY(-81.82%);}100.00%{transform:translateY(-90.91%);}}
@keyframes slide11 { 0%{transform:translateY(0%);}7.88%{transform:translateY(-0.00%);}9.09%{transform:translateY(-8.33%);}16.97%{transform:translateY(-8.33%);}18.18%{transform:translateY(-16.67%);}26.06%{transform:translateY(-16.67%);}27.27%{transform:translateY(-25.00%);}35.15%{transform:translateY(-25.00%);}36.36%{transform:translateY(-33.33%);}44.24%{transform:translateY(-33.33%);}45.45%{transform:translateY(-41.67%);}53.33%{transform:translateY(-41.67%);}54.55%{transform:translateY(-50.00%);}62.42%{transform:translateY(-50.00%);}63.64%{transform:translateY(-58.33%);}71.52%{transform:translateY(-58.33%);}72.73%{transform:translateY(-66.67%);}80.61%{transform:translateY(-66.67%);}81.82%{transform:translateY(-75.00%);}89.70%{transform:translateY(-75.00%);}90.91%{transform:translateY(-83.33%);}98.79%{transform:translateY(-83.33%);}100.00%{transform:translateY(-91.67%);}}

</style>
